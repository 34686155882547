import React from 'react'
import {Col, Container, Row, Visible} from 'react-grid-system'
import styled from 'styled-components'

import {Icon, portalTheme} from 'pyrexx-react-library'
import {useTranslation} from "react-i18next";

const BulletinCard = (props) => {
  const {t} = useTranslation()
  const {location, datetime, resetSelector, currentFloorGroup} = props

  return (
    <ContainerStyled>
      <Visible sm md xl lg>
        <Row>
          <Col
            xs={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <LocationIcon/>
          </Col>

          <Col xs={10}>
            <TextStyled>{location?.street} {location?.number}
              {location?.town ? (
                <> {location?.zip}, {location?.town}</>
              ) : (
                <> {location?.zip}</>
              )}
              <br />
              {currentFloorGroup ? currentFloorGroup : ''}
              <LinkStyled
                onClick={resetSelector}> {t('I LIVE IN A DIFFERENT FLOOR')}</LinkStyled>
            </TextStyled>
          </Col>
        </Row>
        <Row style={{marginTop: '2rem'}}>
          <Col
            xs={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <CalendarIcon/>
          </Col>

          <Col xs={10}>
            <TextStyled>{datetime ? datetime : ''}</TextStyled>
          </Col>
        </Row>
      </Visible>

      <Visible xs>
        <Row>
          <Col>
            <Row justify='center' style={{margin: '2rem 0'}}>
              <LocationIcon/>
              <TextStyled>{location?.street} {location?.number}
                {location?.town ? (
                  <> {location?.zip}, {location?.town}</>
                ) : (
                  <> {location?.zip}</>
                )} <br />
                <>{currentFloorGroup ? currentFloorGroup : ''}</> <LinkStyled
                  onClick={resetSelector}> {t('I LIVE IN A DIFFERENT FLOOR')}</LinkStyled>
              </TextStyled>
            </Row>
            <Row justify='center' style={{margin: '2rem 0'}}>
              <CalendarIcon/>
              <TextStyled>{datetime ? datetime : ''}</TextStyled>
            </Row>
          </Col>
        </Row>
      </Visible>
    </ContainerStyled>
  )
}

const ContainerStyled = styled(Container)`
  border: 1px solid;
  border-color: #8ba8c8;

  padding: 1rem;
  margin-bottom: 1.5rem;
`

const TextStyled = styled.p`
  font-family: ${portalTheme.font.family.quicksand};
  font-size: ${portalTheme.font.size.headingSmall};
  margin: 0;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  margin-left: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const CalendarIcon = styled(Icon).attrs((props) => ({
  icon: 'calendar',
  size: '45px',
  color: '#8ddc64',
}))``

const LocationIcon = styled(Icon).attrs((props) => ({
  icon: 'location',
  size: '55px',
  color: '#8ddc64',
}))``


const LinkStyled = styled.a`
  text-align: center;
  color: #91d1d9;
  cursor: pointer;
`

export default BulletinCard
